import React, {useEffect, useRef, useState} from 'react'
import GaugeChart from 'react-gauge-chart'
import MovingAverage from "../../lib/movingAverage";
import {scaledWeight, unitOfWeight} from "../../lib/i18n"

export default function Load({address, metrics, load, member}) {
  const [arcs, setArcs] = useState([0.3,0.5,0.2])
  const [max, setMax] = useState(metrics.best || 1000)
  const [percent, setPercent] = useState(0)
  const [units, setUnits] = useState('#')
  const [value, setValue] = useState(-13)
  const [movingAvg, setMovingAvg] = useState(0)

  const MovingAvg = useRef(new MovingAverage())


  const tare = async()=>{
    await fetch(`https://${address}:3000/load/calibrate`)
    setTimeout(() => {
      fetch(`https://${address}:3000/load/initialize`)
    }, 456)      
  }

  const gaugeText = ()=>movingAvg


  useEffect(() => {
    const units = (member && member.stats)? member.stats.units: 'Metric'
    if (member) {
    }
    setValue(scaledWeight(Math.abs(Math.round(load.data/1000)), units))
  }, [load.data])

  useEffect(() => {
    const units = (member && member.stats)? member.stats.units: 'Metric'
    setUnits(unitOfWeight(units))
  }, [member])

  useEffect(() => {
    if (value > max) setMax(value)
    setPercent(value/max)

    const arc1 = metrics.first / max
    const arc2 = metrics.last / max
    const arc3 = metrics.best / max
    setArcs([arc1, arc2, arc3])

    if (MovingAvg.current.update) {
      MovingAvg.current.update(value)
      setMovingAvg(Math.round(+MovingAvg.current))
    }
  }, [max, metrics, value])


  return (
    <div className="load gauge">
      {/*
      <div onClick={tare}>
        <GaugeChart
          arcsLength={arcs}
          arcPadding={0.02}
          colors={['#777', '#EEB735', '#FFF']}
          formatTextValue={gaugeText}
          // hideText
          needleColor="#EEB735"
          baseNeedleColor="#FFF"
          percent={percent}
        />

      </div>
      */}
      <h1 className="liveLoad">{value}{units}</h1>
      <button className="tare button" type="button" onClick={tare}><em>tare</em></button>
    </div>
  )
}