const colors = [
  'white',
  'orange',
  'red',
  'yellow',
  'blue',
  'black',
]

const distance = (x1,y1,x2,y2)=>Math.sqrt((x2-x1)**2 + (y2-y1)**2)

const nose = (keypoints)=>keypoints[0] ? keypoints[0].position : 0
const leftEye = (keypoints)=>keypoints[1] ? keypoints[1].position : 0
const rightEye = (keypoints)=>keypoints[2] ? keypoints[2].position : 0
const leftEar = (keypoints)=>keypoints[3] ? keypoints[3].position : 0
const rightEar = (keypoints)=>keypoints[4] ? keypoints[4].position : 0
const leftShoulder = (keypoints)=>keypoints[5] ? keypoints[5].position : 0
const rightShoulder = (keypoints)=>keypoints[6] ? keypoints[6].position : 0
const leftElbow = (keypoints)=>keypoints[7] ? keypoints[7].position : 0
const rightElbow = (keypoints)=>keypoints[8] ? keypoints[8].position : 0
const leftWrist = (keypoints)=>keypoints[9] ? keypoints[9].position : 0
const rightWrist = (keypoints)=>keypoints[10] ? keypoints[10].position : 0
const leftHip = (keypoints)=>keypoints[11] ? keypoints[11].position : 0
const rightHip = (keypoints)=>keypoints[12] ? keypoints[12].position : 0


export function checkUserMediaError() {
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    return new Error(
      "Browser API navigator.mediaDevices.getUserMedia not available"
    )
  }
  return null
}

export function getMediaStreamConstraints(facingMode, frameRate) {
  return {
    audio: false,
    video: {
      facingMode,
      frameRate
    }
  }
}

export function getConfidentPoses(poses, minPoseConfidence, minPartConfidence) {
  return poses
    .filter(({ score }) => score > minPoseConfidence)
    .map(pose => ({
      ...pose,
      keypoints: pose.keypoints.filter(({ score }) => score > minPartConfidence)
    }))
}

export function drawKeypoints(ctx, keypoints, index) {
  ctx.beginPath()
  ctx.moveTo(leftWrist(keypoints).x,leftWrist(keypoints).y)
  ctx.lineTo(leftElbow(keypoints).x,leftElbow(keypoints).y)
  ctx.lineTo(leftShoulder(keypoints).x,leftShoulder(keypoints).y)
  ctx.lineTo(rightShoulder(keypoints).x,rightShoulder(keypoints).y)
  ctx.lineTo(rightElbow(keypoints).x,rightElbow(keypoints).y)
  ctx.lineTo(rightWrist(keypoints).x,rightWrist(keypoints).y)
  ctx.strokeColor = colors[index]
  ctx.closePath()
  ctx.stroke()

  keypoints.forEach(({part,position,score}) => {
    const { x, y } = position
    ctx.beginPath()
    ctx.arc(x, y, 5*score, 0, 2 * Math.PI, false)
    ctx.fillStyle = colors[index]
    ctx.fill()
    ctx.closePath()
  })
}