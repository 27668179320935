import React, {useEffect, useState} from 'react'
import useWebSocket from "../../lib/useWebSocket";
import Position from "./Position";
import Graph from "./SessionGraph";
import { barLevels, peakPerformance, post } from "../../lib/helpers";
import {scaledWeight, unitOfWeight} from "../../lib/i18n"


export default function Session({
  hardware, 
  load, 
  member,
  metrics,
  position, 
  sessions, 
}) {
  const [best, setBest] = useState(1)
  const [first, setFirst] = useState(1)
  const [gt, setGt] = useState(1)
  const [high, setHigh] = useState(1)
  const [last, setLast] = useState(1)
  const [peak, setPeak] = useState(1)
  const [rgt, setRgt] = useState(1)
  const [MOB, setMOB] = useState(0)

  // const addr = {
  //   load: `wss://${hardware.ip}:3000/load`,
  //   loadAlt: `https://${hardware.ip}:3000/load`,
  //   machine: `https://${hardware.ip}:3000/machine`,
  //   position: `wss://${hardware.ip}:3000/position`,
  //   positionAlt: `https://${hardware.ip}:3000/position`,
  //   status: `wss://${hardware.ip}:3000/status`,
  //   settings: `https://${hardware.ip}:3000/ux/config.html`,
  // }

  // const load = useWebSocket(addr.load, addr.loadAlt)
  // const position = useWebSocket(addr.position, addr.positionAlt)
  // const status = useWebSocket(addr.status)

  useEffect(() => {
    try {
      const newFirst = metrics.first
      const newBest = metrics.best
      const newLast = metrics.last
      const newPeak = metrics.peak

      setFirst(newFirst)
      setLast(newLast)
      setBest(newBest)
      setPeak(newPeak)
    } catch (error) {
      setFirst(0)
      setLast(0)
      setBest(0)
      setPeak(0)
    }
  }, [sessions])

  useEffect(() => {
    try {
      setGt(barLevels[hardware.name].gt)
      setRgt(barLevels[hardware.name].rgt)
    } catch (error) {
      setGt(1)
      setRgt(1)
    }
  }, [hardware])

  useEffect(() => {
    try {
      const bodyWeight = (member && member.stats)? member.stats.weight: 100
      const units = (member && member.stats)? member.stats.units: 'Metric'

      let newHigh = scaledWeight(Math.abs(+load.data / 1000), units)
      newHigh = Math.max(high, newHigh)

      const newMOB = Math.max(MOB, newHigh / bodyWeight)

      setHigh(newHigh)
      setMOB(newMOB)
    } catch (error) {
      setHigh(0)
      setMOB(0)
    }
  }, [load.data])


  function save() {
    // {machine, member_id, force, mob, weight, position, timestamp}
    post(`member/log/session`, {
      machine: hardware.name,
      member_id: member.id,
      force: high,
      mob: MOB,
      body_weight: member.stats.weight,
      position: position.data,
      timestamp: new Date().toISOString(),
    })
  }


  return (
    <div>
      <div className="session-header">
        <span>Load {Math.round(high)}</span>
        {/* <button
          className="save button"
          onClick={save}
          type="button"
        >Save</button> */}
        <span>MOB {MOB.toFixed(1)}</span>
      </div>

      <Graph
        best={best}
        current={MOB}
        first={first}
        gt={gt}
        last={last}
        peak={peak}
        rgt={rgt}
      />
    </div>
  )
}