import React,{useEffect,useRef,useState} from 'react'
import {Link} from 'react-router-dom'
import useWebSocket from "../../lib/useWebSocket";
import Load from "./Load";
import Commands from "./Commands";
import Classes from '../../lib/Classes'
import Position from "./Position";
// import Settings from "./Settings";
import { scrubbedData, metrics } from "../../lib/report";
import Metrics from "./Metrics";
import lightning from "../../img/icon/lightning.svg";
import History from "./History";
import Session from "./Session";


export default function Spectrum({name, type, address, member}) {
  const addr = {
    load: `wss://${address}:3000/load`,
    loadAlt: `https://${address}:3000/load`,
    machine: `https://${address}:3000/machine`,
    position: `wss://${address}:3000/position`,
    positionAlt: `https://${address}:3000/position`,
    status: `wss://${address}:3000/status`,
    settings: `https://${address}:3000/ux/config.html`,
  }

  const secureLink = useRef(null)

  const [machine, setMachine] = useState({
    name:'unknown',
    minimum_position: 0,
    maximum_position: 100,
  })
  const [machineMetrics, setMachineMetrics] = useState({
    best: 500,
    first: 100,
    last: 250,
    peak: 150,
  })
  const [slowConx, setSlowConx] = useState(false)
  const [secured, setSecured] = useState(false)
  const [sessions, setSessions] = useState([])

  const load = useWebSocket(addr.load, addr.loadAlt)
  const position = useWebSocket(addr.position, addr.positionAlt)
  const status = useWebSocket(addr.status)

  // const bumpDuration = 250
  const classes = new Classes('')

  // function getPosition(){
  //   return fetch(`https://${address}:3000/position`)
  //   .then(res=>res.json())
  //   .catch(err=>console.error(err))
  // }

  // function getLoad(){
  //   return fetch(`https://${address}:3000/load`)
  //   .then(res=>res.json())
  //   .catch(err=>console.error(err))
  // }


  useEffect(() => {
    if (secureLink.current) {
      secureLink.current.classList.add('animate__animated')
      secureLink.current.classList.add('animate__rubberBand')
    }
  }, [])

  useEffect(() => {
    fetch(addr.machine)
    .then(res=>res.json())
    .then(res=>{
      setMachine(res)
      setSecured(true)
    })
    .catch(err=>{
      setSecured(false)
    })
  }, [address, addr.machine])

  useEffect(() => {
    if (status.data.error) classes.add('error')
    else classes.remove('error')
  }, [status.data])

  useEffect(() => {
    if (member) {
      const theseSessions = scrubbedData(member.sessions, machine.name)

      setSessions(theseSessions)
      setMachineMetrics(metrics(theseSessions, member.stats.weight))
    }
  }, [machine, member])

  useEffect(() => {
    if (!(load.state.type && (load.state.type==='open'))
      || !(position.state.type && (position.state.type==='open'))
    ) {
      setSlowConx(true)
    } else {
      setSlowConx(false)
    }

  }, [load.data, load.state, position.data, position.state])


  return (<div className="glow outline vertical flex control-list">
    <div className="row panel">
      {slowConx? <img height="24" width="24" src={lightning} />: <></>}

      <h1 className="capitalize"> {type} {machine.name} </h1>

      {secured?
        <></>
        : <>
          <Link 
            to={{pathname: addr.machine}} 
            target="_blank" 
            ref={secureLink} 
            className="secure link">Secure this connection</Link>
          {/* <iframe id="security-iframe" name="security-iframe" /> */}
        </>
      }
    </div>


    <Load
      address={address}
      load={load}
      metrics={machineMetrics}
      member={member} 
    />

    <Session
      hardware={machine}
      load={load}
      member={member}
      metrics={machineMetrics}
      position={position}
      sessions={sessions}
    />

    <Position address={address} machine={machine} member={member} status={status} value={position.data} />

    <Commands address={address} member={member} />
{/* 
    {secured?
      <Link className="settings link" to={{pathname: addr.settings}} target="_blank">Settings</Link>
      : <></>
    }
*/}
{/*
      {member?
        <li>
          <Metrics metrics={machineMetrics} value={load.data} />
        </li>
      :<></>
      } 
*/}
  </div>)
}