import React,{useEffect} from 'react'
import useLocalStorage from "../lib/useLocalStorage";
import {
  Redirect,
  useHistory
} from 'react-router-dom'

function Logout({setAuthenticated, setUser}) {
  const [creds,setCreds] = useLocalStorage('creds',null)

  const history = useHistory()

  useEffect(() => {
    setAuthenticated(false)
    setCreds({})
    setUser({})
    history.push('/')
  }, [])

  return (
    <div>
      logged out
    </div>
  )
}

export default Logout
