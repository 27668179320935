import React, {useEffect, useRef, useState} from 'react'
import QrReader from "react-qr-reader";
import redX from "../img/redX.png";


function AddMachine({machines,setMachines}) {
  const scanHeader = useRef(null)
  const newName = useRef(null)
  const newAddress = useRef(null)

  const [facingMode, setFacingMode] = useState('environment')
  const [legacyMode, setLegacyMode] = useState(false)
  const [newType, setNewType] = useState('')
  const [visible, setVisible] = useState(false)


  useEffect(() => {
    if (visible) {
      window.location.href = "#"
      window.location.href = "#scan-header"
      window.scrollTo(scanHeader.current)
    }
  }, [visible])

  const addMachineHandler = event=>{
    event.preventDefault()

    const newMachine = {
      type: newType,
      name: newName.current.value,
      address: newAddress.current.value,
    }

    setMachines([...machines, newMachine])
    setVisible(false)
  }

  const addressScan = data=>{
    if (data) {
      newAddress.current.value = data

      if (!newName.current.value) newName.current.value = newType

      fetch(`https://${data}:3000/machine`)
      .then(res=>res.json())
      .then(res=>{
        newName.current.value=res.name
        newType.current.value='spectrum'
      })
      .catch(err=>console.error(err))
    }
  }

  const scanError = err=>console.error(err)

  const selectType = event=>{
    console.log(event.target)
    try {
      setNewType(event.target.value)
      newName.current.value = event.target.selectedOptions[0].text
    } catch (error) {
      console.error(error)
    }
  }

  let facingEnv = true
  const toggleFacing = event=>{
    facingEnv = !facingEnv

    if (facingEnv) {
      setFacingMode('environment')
    } else {
      setFacingMode('user')
    }
  }

  // let legacy = false
  // const toggleLegacy = event=>{
  //   legacy = !legacy

  //   setLegacyMode(legacy)
  // }

  if (visible) {
    return <form className="add machine container" onSubmit={addMachineHandler}>
      <div className="header" id="scan-header" ref={scanHeader}>
        <h3>Scan Machine Code</h3>
        <button
          className="close button"
          onClick={event=>setVisible(false)}
          type="button"
          >
          <img src={redX} height="32" width="32" />
        </button>
      </div >
      <ul>
        <li>
          <QrReader
            delay={300}
            onError={scanError}
            onScan={addressScan}
            facingMode={facingMode}
            legacyMode={legacyMode}
          />
          <button className="camera button direction" type="button" onClick={toggleFacing}>Direction</button>
          {/* <button type="button" onClick={toggleLegacy}>Snap</button> */}
        </li>
        <li className="center content">
          <select 
            onChange={selectType}
            value={newType}
          >
            <option value="">Select Machine Type</option>
            <option value="spectrum">Spectrum</option>
            <option value="redlight">Red Light</option>
            <option value="biocharger">Bio-Charger</option>
          </select>
        </li>
        <li className="center content">
          <input placeholder="machine name" ref={newName} />
        </li>
        <li className="center content">
          <input placeholder="machine address" ref={newAddress} />
        </li>
        <li className="center content">
          <button type="submit">Add Machine</button>
        </li>
      </ul>
    </form>
  } else {
    return <button onClick={event=>setVisible(true)}>Add Machine</button>
  }
}

export default AddMachine