import React from 'react'

function RedLight({name,type,address}) {
  return (
    <div>
      <h3> {name} {type} {address} </h3>
    </div>
  )
}

export default RedLight