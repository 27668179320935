export default class MovingAverage {
  constructor(initialValue=0, factor=5) {
    this.factor = factor
    this.value = initialValue

    this.average = this.value
    this.previous = this.value
  }

  update(val) {
    this.average = this.previous + ((val - this.previous) / this.factor)

    this.previous = this.average
  }

  valueOf() {
    return this.average
  }
}