import { logicalNot } from '@tensorflow/tfjs-core'
import React,{useEffect, useRef, useState} from 'react'
import {
  Link,
} from 'react-router-dom'


export default function List({data}) {
  const filterInput = useRef(null)

  const [filter, setFilter] = useState('')
  const [list, setList] = useState([])

  const updateFilter = event=>setFilter(event.target.value)


  useEffect(() => {
    if (filterInput.current) filterInput.current.focus()
  }, [])

  useEffect(() => {
    if (filter && filter.length && (filter.length > 1)) {
      const newList = data.filter(el=>{
        const nameIncluded = (el.name)? el.name.toLowerCase().includes(filter.toLowerCase()): false
        const loginIncluded = (el.login_id)? el.login_id.toLowerCase().includes(filter.toLowerCase()): false

        return nameIncluded || loginIncluded
      })
      setList(newList)
    } else {
      const newList = data.slice(0, 20)
      newList.push({id:-7, name:'...type above for more'})
      setList(newList)
    }
  }, [data, filter])


  function MemberSelect({member}) {
    if (member) {
      if (member.id >= 0) {
        return <li>
          <Link to={`/member/${member.id}`}>{member.name}</Link>
        </li>
      } else {
        return <>
          <br/>
          <a href="#filter">{member.name}</a>
        </>
      }
    }

    return null
  }

  function Selections({elements}) {
    if (elements) {
      return <ul>{elements.map(el=><MemberSelect key={el.id} member={el} />)}</ul>
    }

    return null
  }


  if (data && data.length) {
    return <>
      <form>
        <a name="filter"></a>
        <input
          className="w-auto"
          onChange={updateFilter}
          onKeyUp={updateFilter}
          placeholder="Member Name"
          ref={filterInput}
          value={filter}
        />
      </form>
      <br/>
      <Selections elements={list} />
    </>
  } else {
    return <></>
  }
}