export function metrics(sessions, bodyWeight) {
  sessions.sort((a,b)=>{
    if (a.timestamp < b.timestamp) return -1
    else if (a.timestamp > b.timestamp) return 1
    else return 0
  })

  const bestSession = sessions
  .map(el=>el.data_int)
  .reduce((a,b)=>Math.max(a,b), 0)

  const firstSession = sessions.length ? sessions[0].data_int : 0

  const lastSession = sessions.length ? sessions[sessions.length-1].data_int : 0

  if (bodyWeight) {
    return {
      best: bestSession / bodyWeight,
      first: firstSession / bodyWeight,
      last: lastSession / bodyWeight,
      peak: peakPerformance(sessions) / bodyWeight,
    }
  } else {
    return {
      best: bestSession,
      first: firstSession,
      last: lastSession,
      peak: peakPerformance(sessions),
    }
  }
}

export function topTen(sessions) {
  sessions.sort((a,b)=>{
    if (a.data_int < b.data_int) return -1
    else if (a.data_int > b.data_int) return 1
    else return 0
  })

  return sessions.slice(0,10)
}

export function peakPerformance(sessions) {
  const top = topTen(sessions)

  const topAverage = top.reduce((a,b)=>a+b.data_int,0) / top.length
  const peak = topAverage * 0.85

  return peak
}

export function scrubbedData(allSessions, machineName) {
  let sessions = allSessions.filter(el=>el.origin.toLowerCase()===machineName.toLowerCase())

  // keep only the sessions that have data and a date-time
  sessions = sessions.filter(el=>el.data_float)
  sessions = sessions.filter(el=>el.timestamp)

  return sessions
}

export function improvement(metrics) {
  let performance = 1
  if (metrics.first && metrics.best) {
    performance = (metrics.best / metrics.first) - 1
  }

  if (!isFinite(performance)) performance = 100

  return performance    
}