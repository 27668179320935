import React, {useEffect, useState} from 'react'
import {post, exbregatedPosition} from "../../lib/helpers";


export default function Position({address, machine, member, status, value}) {
  const [dumbPosition, setDumbPosition] = useState(value)
  const [targetPosition, setTargetPosition] = useState(0)
  const [targetPosition_raw, setTargetPosition_raw] = useState(0)

  function extend(event) {
    if (event) event.preventDefault()

    if (status.state && (status.state.type!=='close')) {
      status.transmit({extend:true})
    } else {
      fetch(`https://${address}:3000/position/extend`, {extend:true})
    }

    return false
  }

  function gotoTargetPosition(event) {
    if (event) event.preventDefault()

    fetch(`https://${address}:3000/position?value=${targetPosition_raw}`)
    .then(res=>res.json())
    .catch(err=>console.error(err))

    return false
  }

  function halt(event) {
    if (event) event.preventDefault();

    fetch(`https://${address}:3000/position/halt`, {halt:true})
    status.transmit({halt:true})

    // if (status.state && (status.state.type!=='close')) getPosition()

    return false
  }

  function lock() {
    // command(address, {position_lock_save:true})
    halt()

    post('member/position/save', {
      value: value,
      member_id: member.id,
      origin: machine.name,
    })
    .then(()=>{
      console.log('position saved')
      setTargetPosition(exbregatedPosition(value))
    })
    .catch(error=>{
      console.error(error)
    })
  }

  function retract(event) {
    if (event) event.preventDefault();

    if (status.state && (status.state.type!=='close')) {
      status.transmit({retract:true})
    } else {
      fetch(`https://${address}:3000/position/halt`, {halt:true})
    }

    return false
  }


  useEffect(() => {
    if (member) {
      const key = `${machine.name}_position`
      const val = member.stats[key]

      setTargetPosition_raw(val)
      setTargetPosition(exbregatedPosition(val))
    }
  }, [machine, member])

  useEffect(() => {
    setDumbPosition(exbregatedPosition(value))
  }, [value])


  function TargetPosition() {
    if (targetPosition) {
      return <button
        type="button"
        onClick={gotoTargetPosition}
      >
        Set Position {targetPosition}
      </button>
    } else {
      return <div></div>
    }
  }

  return <ul className="outline">
    <li>
      <div className="flexrow list">
        <div className="control value">
          <div>Position: {dumbPosition}</div>
        </div>

        <div className="control button">
          <TargetPosition />
        </div>
      </div>
    </li>
    <li className="center content">
      <div className="flexrow list">
        <button className="control button" type="button"
          onMouseDown={retract}
          onTouchStart={retract}
          
          onMouseUp={halt}
          onMouseOut={halt}
          onMouseLeave={halt}
          onTouchCancel={halt}
          onTouchEnd={halt}
          onDragEnd={halt}
          >
          Retract
        </button>

        <button className="control button" type="button" onClick={lock}>
          Lock
        </button>

        <button className="control button" type="button"
          onMouseDown={extend}
          onTouchStart={extend}
          
          onMouseUp={halt}
          onMouseOut={halt}
          onMouseLeave={halt}
          onTouchCancel={halt}
          onTouchEnd={halt}
          onDragEnd={halt}
          >
          Extend
        </button>
      </div>
    </li>

    <li className="center content">
    </li>
  </ul>
}