import React, { useRef, useState, useEffect } from "react"
import Loading from "./Loading"
import useInputImage from "../../lib/useInputImage"
import useLoadPoseNet from "../../lib/useLoadPoseNet"
import { drawKeypoints, getConfidentPoses } from "../../lib/util_bodyCalc"

export default function PoseNet({
  style={},
  className="",
  facingMode="environment",
  frameRate=10,
  input=undefined,
  onEstimate=() => {},
  inferenceConfig={},
  modelConfig={},
  minPoseConfidence=0.1,
  minPartConfidence=0.1,
  width=600,
  height=500,
}) {
  const videoRef = useRef()
  const canvasRef = useRef()
  const net = useLoadPoseNet(modelConfig)
  const [errorMessage, setErrorMessage] = useState()
  const onEstimateRef = useRef()
  const inferenceConfigRef = useRef()
  onEstimateRef.current = onEstimate
  inferenceConfigRef.current = inferenceConfig
  const image = useInputImage({
    input,
    width,
    height,
    videoRef,
    facingMode,
    frameRate
  })

  useEffect(() => {
    if (!net || !image) return () => {}
    if ([net, image].some(elem => elem instanceof Error)) return () => {}

    const ctx = canvasRef.current.getContext("2d")
    const intervalID = setInterval(async () => {
      try {
        const poses = await net.estimatePoses(image, inferenceConfigRef.current)
        const confidentPoses = getConfidentPoses(
          poses,
          minPoseConfidence,
          minPartConfidence
        )
        ctx.drawImage(image, 0, 0, width, height)
        onEstimateRef.current(confidentPoses)
        confidentPoses.forEach(({keypoints}, index) => drawKeypoints(ctx, keypoints, index))
      } catch (err) {
        clearInterval(intervalID)
        setErrorMessage(err.message)
      }
    }, Math.round(1000 / frameRate))

    return () => clearInterval(intervalID)
  }, [
    frameRate,
    height,
    image,
    minPartConfidence,
    minPoseConfidence,
    net,
    width
  ])

  return (
    <>
      <Loading name="model" target={net} />
      <Loading name="input" target={image} />
      <font color="red">{errorMessage}</font>
      <video
        playsInline
        ref={videoRef}
        style={{ width: "0", height: "0" }}
        width={width}
        height={height}
        id="poseVideo"
      />
      <canvas
        style={style}
        className={className}
        ref={canvasRef}
        width={width}
        height={height}
        id="poseCanvas"
      />
      <ul>

      </ul>
    </>
  )
}
