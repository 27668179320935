import React,{useEffect, useRef, useState} from 'react'
import CanvasJSReact from '../../lib/canvasjs.react'

var CanvasJSChart = CanvasJSReact.CanvasJSChart
var CanvasJS = CanvasJSReact.CanvasJS

export default function SessionGraph({best, current, first, gt, peak, rgt, levels, origin="core", title=""}) {
  const chart = useRef(null)

  const options = function(){
    if (best && current && first && gt && peak && rgt && chart && chart.current){
      let data = [
        { label: 'First', y: first, },
        { label: 'GT', y: gt, },
        { label: 'RGT', y: rgt, },
        { label: 'Peak', y: peak, },
        { label: 'Best', y: best, },
        { color: 'white', label: 'Now', y: current, },
      ]

      data.sort((a,b)=>{
        if (a.y > b.y) return 1
        if (a.y < b.y) return -1
        return 0
      })

      return {
        animationEnabled: true,
        theme: "dark1", // "light1", "light2", "dark1", "dark2"
        axisX: {
          labelAngle: -50,
          labelFontColor: "#EEB735",
          labelFontSize: 15,    
        },
        axisY: {
          interlacedColor: "rgba(238,153,83,0.1)",
          gridColor: "black",
          includeZero: true,
          labelFontColor: "#EEB735",
          labelFontSize: 17,
          minimum: 0,
          title: "Multiple of Bodyweight",
          titleFontColor: "#EEB735",
          titleFontSize: 20,
        },
        title: title,
        titleFontColor: "#EEB735",
        titleFontSize: 20,
        backgroundColor: 'black',
        color: "#EEB735",
        data: [{        
          color: "#EEB735",
          dataPoints: data,
          type: "column",
        }],
    }
    } else {
      return {}
    }
  }


  return (
    <div className="stacked session chart">
      <CanvasJSChart onRef={ref=>chart.current=ref} className="base chart" options={options()} />
    </div>
  )
}