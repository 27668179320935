import React, {useEffect,useRef,useState} from 'react'
import Modeler from './PoseNet'

function Poser() {
  const [pose, setPose] = useState([])
  
  function handlePoses(poses) {
    let rawPose = poses[0] ? poses[0].keypoints : []
    let thisPose = []

    for (let I=0; I<12; I++){
      thisPose.push(rawPose[I]?rawPose[I]:{part:''})
    }

    setPose(thisPose)
  }

  const listItems = pose.map(item=><li>{item.part}</li>)

  const capturePose = ()=>{
    let poseVideo = document.getElementById('poseVideo')
    let poseCanvas = document.getElementById('poseCanvas')
    let image = document.getElementById('captureImage')

    image.setAttribute('src', poseCanvas.toDataURL())
  }

  return (
    <table className="poser"><tbody><tr>
      <td>
        <ul>
          {listItems}
        </ul>
      </td>
      <td>
        <Modeler onEstimate={handlePoses} />
        <button onClick={capturePose}>Capture</button>
      </td>
      <td>
        <img id="captureImage" />
      </td>
    </tr></tbody></table>
  )
}

export default Poser
