import React,{useEffect,useState} from 'react'
import {
  useParams,
} from 'react-router-dom'
import { post } from './lib/helpers'


export default function Location({locations}) {
  const [location, setLocation] = useState({name:'Loading...'})
  const {id} = useParams()

  const locationById = id=>locations ? locations.filter(el=>el.id===id) : {}

  useEffect(() => {
    const aborter = new AbortController()
    const response = post('location', {id: id}, aborter.signal)

    response.then(loc=>setLocation(loc[0])).catch(err=>console.error(err))

    return ()=>aborter.abort()
  }, [id])

  return (<>
    <ul>
      {/* {JSON.stringify(location,null,2)} */}
      <li>Name: {location.name}</li>
    </ul>
{/* 
    <div>{JSON.stringify(locationById(id,null,2))}</div> */}
  </>)
}