import {API} from '../config'


export const barLevels = {
  "core": {
    gt: 1.0,
    rgt: 1.5,
  },
  "lower": {
    gt: 4.2,
    rgt: 7.2,
  },
  "postural": {
    gt: 2.0,
    rgt: 2.5,
  },
  "upper": {
    gt: 2.0,
    rgt: 2.5,
  },
}

export async function command(address='', data={}) {
  const body = JSON.stringify(data)
  const url = `https://${address}:3000/cmd`

  try {
    return await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: `${body}`, // body data type must match "Content-Type" header
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json' 
      },
    })
  } catch (error) {
    console.error(error)
  }
}

/** posts to OsteoStrong server
 * @param url_fragment ~ this fragment gets prepended with the remote-host
 * @param data ~ the request body
 */
export async function post(url_fragment = '', data = {}, signal) {
  sessionStorage.setItem('loading', true)

  data = {...data, _phase:process.env.NODE_ENV}

  let body = JSON.stringify(data)

  const token = sessionStorage.getItem('jwt_token')

  const url = API.URL + '/' + url_fragment

  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    body: `${body}`, // body data type must match "Content-Type" header
    // mode: 'no-cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'include', // include, *same-origin, omit
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json' 
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    signal: signal,
  });

  sessionStorage.setItem('loading', false)

  return await response.json(); // parses JSON response into native JavaScript objects
}

export async function get(url_fragment = '', params={}) {
  // Default options are marked with *

  let query = ['']
  if (params) {
    query.push('?')
    for (let key in params) {
      query.push(key)
      query.push(params[key])
    }
  }

  const url = API.URL + '/' + url_fragment + query

  const response = await fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'include', // include, *same-origin, omit
    headers: {
      Accept: 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('jwt_token'),
      'Content-Type': 'application/json'
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
  });

  sessionStorage.setItem('loading', false)

  return response.json(); // parses JSON response into native JavaScript objects
}

export function peakPerformance(data) {
  const peakLength = 10

  let theData = data.slice()

  theData = theData
  .sort((a, b) => {
    if (a.mob < b.mob) {
      return 1;
    }
    if (a.mob > b.mob) {
      return -1;
    }
    return 0; // a must be equal to b
  })
  .slice(0, peakLength)

  // let peak = 0
  // theData.forEach(el=>peak+=el.mob)
  // peak /= peakLength
  
  const avg = theData.reduce((total, next) => total + next.mob, 0) / theData.length

  return avg * 0.85
}
export async function service(address, url_fragment, params={}) {
  let query = ['']
  if (params) {
    query.push('?')
    for (let key in params) {
      query.push(key)
      query.push('=')
      query.push(params[key])
    }
  }

  const url = `https://${address}:3000/${url_fragment}` + query.join('')

  const response = await fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'include', // include, *same-origin, omit
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
  });

  return response.json()
}


export function reformatPhone(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

export const exbregatedPosition = val=>Math.round((val/5)*10)/10

export function weightString(pounds, units) {
  if (units === 'Metric') {
    let kilos = pounds / 2.20462
    kilos = kilos.toFixed(2)
    return `${kilos}kg`
  } else {
    const lbs = Math.trunc(pounds)

    return `${lbs}lb`
  }
}
