import React,{useEffect,useState} from 'react'
import {
  useParams,
} from 'react-router-dom'
import Spectrum from "./Spectrum";
import { post } from "../lib/helpers";
import {reformatPhone} from "../lib/helpers";
import { scaledWeight, unscaledWeight } from '../lib/i18n';


export default function Details() {
  const [bodyWeight, setBodyWeight] = useState(100)
  const [logs, setLogs] = useState([])
  const [member, setMember] = useState({})
  const [stats, setStats] = useState({})

  let { member_id } = useParams()

  const dumbPosition = pos=>Math.round((pos/5)*10)/10

  useEffect(() => {
    const aborter = new AbortController()
    const result = post(`member`, {member_id:member_id}, aborter.signal)

    result.then(res=>setMember(res[0])).catch(err=>console.error(err))

    return ()=>aborter.abort()
  }, [member_id])

  useEffect(() => {
    const aborter = new AbortController()
    const response = post(`member/stats`, {user_id:member_id}, aborter.signal)

    response.then(res=>{
      setStats(res)
      member.stats = res

      changeBodyWeight(member.stats.weight, member.stats.units)
    }).catch(err=>console.error(err))

    return ()=>aborter.abort()
  }, [member])

  useEffect(() => {
    const aborter = new AbortController()
    const response = post(`member/data`, {user_id:member_id}, aborter.signal)

    response
    .then(res=>{
      setLogs(res)
      member.sessions = res
    })
    .catch(err=>console.error(err))

    return ()=>aborter.abort()
  }, [member])


  const changeBodyWeight = (weight, units)=>{
    setBodyWeight(scaledWeight(member.stats.weight, member.stats.units))
  }

  const updateBodyWeight = event=>{
    member.stats.weight = unscaledWeight(event.target.value, member.stats.units)
    changeBodyWeight(member.stats.weight, member.stats.units)
  }


  if (member) {
    return (
      <ul>
        <li>
          {member.name}
        </li>
        <li>
          <span className="member detail label">Phone</span> {reformatPhone(member.phone)}
        </li>
        <li>
          <span className="member detail label">Login-Id</span> {member.login_id}
        </li>
        <li>Stats:</li>
        <li>
          <ul>
            <li>
              Weight: {bodyWeight}
              {/* <input
                max="1000"
                min="10"
                step="5"
                type="number"
                value={bodyWeight}
                onChange={updateBodyWeight}
              /> */}
              </li>
            <li>Saved Positions:</li>
            <ul className="position-list">
              <li>Upper: {dumbPosition(stats.upper_position)}</li>
              <li>Lower: {dumbPosition(stats.lower_position)}</li>
              <li>Core: {dumbPosition(stats.core_position)}</li>
              <li>Postural: {dumbPosition(stats.postural_position)}</li>
            </ul>
          </ul>
          {/* {JSON.stringify(stats)} */}
        </li>
        <hr/>
        {member ? <Spectrum member={member} /> : null}
      </ul>
    )
  } else {
    return <div>Select a member</div>
  }
}