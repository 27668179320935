import React from 'react'
import MachineList from "../Coach/MachineList";


export default function Spectrum({member}) {
  return (
    <div>
      <MachineList member={member} />
    </div>
  )
}