export function scaledWeight(value, system, precision=0) {
  let result = +value

  if (system && system.toLowerCase() === 'metric') result *= 0.453592

  result = result.toFixed(precision)

  return result
}

export function unscaledWeight(value, system, precision=0) {
  let result = +value

  if (system && system.toLowerCase() === 'metric') result /= 0.453592

  result = result.toFixed(precision)

  return result
}

export function unitOfWeight(system) {
  if (system && system.toLowerCase() === 'metric') return 'Kg'
  else return 'Lb'
}