import React from 'react'
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom'
import Bluetooth from "./Bluetooth"
import Qr from "./Qr"

function Test() {
  let { path, url } = useRouteMatch();

  return (
    <div>
      <h1>Test Stuff</h1>
      <Switch>
        <Route exact path={path}>
          <h2>root</h2>
          <Link to={`${url}/bluetooth`}>Bluetooth</Link>
          <br/>
          <Link to={`${url}/qrcode`}>QR</Link>
        </Route>

        <Route exact path={`${path}/bluetooth`}>
          <Bluetooth />
        </Route>

        <Route exact path={`${path}/qrcode`}>
          <Qr />
        </Route>
      </Switch>
    </div>
  )
}

export default Test
