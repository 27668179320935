import React, {useEffect,useRef,useState} from 'react'
import useLocalStorage from "../lib/useLocalStorage";
import {post} from '../lib/helpers'

export default function Login({authenticated, setAuthenticated, user, setUser}) {
  const [errorMessage, setErrorMessage] = useState(null)

  const username = useRef(null)
  const password = useRef(null)

  const [creds,setCreds] = useLocalStorage('creds',null)


  async function formLogin(event){
    event.preventDefault()

    try {
      const newCreds = {
        username: username.current.value || creds.username,
        password: password.current.value || creds.password,
      }

      setCreds(newCreds)

      await login(newCreds)
    } catch (error) {
      setErrorMessage('Login Error')
      console.error('login error', error)
    }
  }

  async function login(creds) {
    if (creds) {
      if (!(creds.username && creds.password)) return
      
      try {
        let response = await post(`login`, creds)

        if (response) {
          if (response.error) setErrorMessage(response.error)
          
          if (response.token) sessionStorage.setItem('jwt_token', response.token)
          
          if (response.user) {        
            setUser(response.user)
            setAuthenticated(true)
          } else {
            setUser(null)
            setAuthenticated(false)
            
            console.error(response)
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  }


  useEffect(() => {
    login(creds)
  }, [])


  if (!authenticated){
    return (
      <div>
        <h1>Login</h1>
        <form
          onSubmit={formLogin}
        >
          <input
            autoFocus
            name="username"
            placeholder="username"
            type="text"
            ref={username}
          />
          <br/>
          <input
            name="password"
            placeholder="password"
            type="password"
            ref={password}
          />
          <br/>
          <button
            type="submit"
          >Login</button>
          <div>{errorMessage}</div>
        </form>
      </div>
    )
  } else {
    return (
      <div>
        <h1>{user.name}</h1>
      </div>
    )
  }
}