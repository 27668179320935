import React from 'react'

function Bluetooth() {
  return (
    <div>
      <h2>Bluetooth Test</h2>
    </div>
  )
}

export default Bluetooth
