import React, {useEffect, useRef, useState} from 'react'
import {command} from "../../lib/helpers";


export default function Commands({address,member}) {
  const actionPanel = useRef(null)
  const loginPanel = useRef(null)

  const [loginId, setLoginId] = useState('')
  const [screen, setScreen] = useState('')

  const memberLogin = event=>{
    console.log('member login')
    event.preventDefault()
    command(address, {member_login: loginId})

    setScreen('action')

    return false
  }

  const memberSave = event=>{
    command(address, {member_save:true})
    setScreen('report')
  }

  const gotoScreen = nextScreen=>{
    const payload = {goto_screen:nextScreen}

    command(address, payload)

    setScreen(nextScreen)
  }

  const buttonStyle = name=>{
    if (screen === name) return 'highlight control button'
    else return 'control button'
  }


  useEffect(() => {
    if (member) {
      setLoginId(member.login_id || member.phone || '')
    }
  }, [member])

  useEffect(() => {
    if (screen !== 'login') loginPanel.current.className = 'hidden'
    else loginPanel.current.className = 'center content'

    if (screen !== 'action') actionPanel.current.className = 'hidden'
    else actionPanel.current.className = 'center content'
  }, [screen])


  return (
    <ul className="outline">
      <li className="panel-header">
        Change Screen
      </li>

      <li className="center content">
        <button 
          type="button" 
          onClick={()=>gotoScreen("login")}
          className={buttonStyle('login')}
        >Login</button>

        <button 
          type="button" 
          onClick={()=>gotoScreen("member")}
          className={buttonStyle('member')}
        >Member</button>

        <button 
          type="button" 
          onClick={()=>gotoScreen("action")}
          className={buttonStyle('action')}
        >Session</button>

        <button 
          type="button" 
          onClick={()=>gotoScreen("report")}
          className={buttonStyle('report')}
        >Report</button>
      </li>

      <li className="hidden" ref={loginPanel}>
        <input
          className="w-auto"
          onChange={event=>setLoginId(event.target.value)}
          placeholder="1111"
          value={loginId}
        />

        <button type="button" onClick={memberLogin}>Member Login</button>
      </li>

      <li className="hidden" ref={actionPanel}>
        <button type="button" onClick={memberSave}>Member Save</button>
      </li>
    </ul>
  )
}