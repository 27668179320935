import React,{useState} from 'react'
import QrReader from "react-qr-reader";
// import QrReader from "react-qr-scanner";

function Qr() {
  const [scan, setScan] = useState({})
  const [error, setError] = useState({})

  function handleError(event) {
    setError(event)
  }

  function handleScan(event) {
    setScan(event)
  }

  return (
    <div>
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '50%' }}
      />

      Scan: {JSON.stringify(scan)}
      Error: {JSON.stringify(error)}
    </div>
  )
}

export default Qr