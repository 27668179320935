import React from 'react'
import Poser from './Poser'

function index() {
  return (
    <div>
      <Poser />
    </div>
  )
}

export default index
