import React from 'react'
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom'
import MachineList from "./MachineList";
import BodyCalc from "./BodyCalc";

function Coach() {
  let { path, url } = useRouteMatch();

  return (
    <div>
      <h1>Coaching</h1>

      <Switch>
        <Route exact path={path}>
          <h2>root</h2>
          <Link to={`${url}/machines`}>Machines</Link>
          <br/>
          <Link to={`${url}/bodycalc`}>Body-Calc</Link>
        </Route>

        <Route exact path={`${path}/machines`}>
          <MachineList />
        </Route>

        <Route exact path={`${path}/bodycalc`}>
          <BodyCalc />
        </Route>
      </Switch>
    </div>
  )
}

export default Coach
