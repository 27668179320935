import React,{useState} from 'react'
import Spectrum from "./Spectrum";
import RedLight from "./RedLight";
import BioCharger from "./BioCharger";

import refreshIcon from '../img/icon/refresh.svg'

function MachineProxy({name,type,address,member,remove}) {
  const [visible, setVisible] = useState(false)

  const hide = event=>setVisible(false)
  const show = event=>setVisible(true)

  function MainContent() {
    switch (type) {
      case 'spectrum':
        return <Spectrum name={name} type={type} member={member} address={address} />
        break;

      case 'redlight':
        return <RedLight name={name} type={type} member={member} address={address} />
        break;

      case 'biocharger':
        return <BioCharger name={name} type={type} member={member} address={address} />
        break;

      default:
        return <div>Unknown {type} {name}</div>
        break;
    }
  }

  if (visible) {
    return <div className="machine interface">
      <button className="refresh button" onClick={hide}>
        <img src={refreshIcon} height="32" width="32" />
      </button>
      <MainContent className="main content" />
      <button className="delete button" type="button" onClick={remove}>delete machine</button>
    </div>
  } else {
    return <div>
      <hr/>
      <button onClick={show}>{type} {name}</button>
      <hr/>
    </div>
  }
}

export default MachineProxy