import React,{useEffect, useState} from 'react'
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom'
import MemberList from "./List"
import Details from "./Details";
import { post } from "../lib/helpers";

export default function Members({user}) {
  const [locationId, setLocationId] = useState({})
  const [locations, setLocations] = useState([])
  const [members, setMembers] = useState([])
  
  let { path } = useRouteMatch();


  useEffect(() => {
    /**
     * Getting locations from the server
     */
    const aborter = new AbortController()
    const response = post(`locations`, {}, aborter.signal)

    response.then(res=>{
      if (res && res.locations) {
        res.locations.sort((a,b)=>{
          if (a.name > b.name) return 1
          else if (a.name < b.name) return -1
          return 0
        })
        
        setLocations(res.locations)
      }
    })
    .catch(err=>console.error(err))

    return ()=>aborter.abort()
  }, [user])

  useEffect(() => {
    /**
     * Getting members from the server after a location is selected
     */
    const aborter = new AbortController()

    if (!isNaN(locationId)){
      const response = post(`members`, {location_id: locationId}, aborter.signal)

      response.then(res=>{
        res.sort((a,b)=>{
          if (a.name > b.name) return 1
          else if (a.name < b.name) return -1
          return 0
        })

        res = res.filter(el=>el.active)

        setMembers(res)
      })
    }

    return ()=>aborter.abort()
  }, [locationId])


  function Location({data}) {
    if (data) {
      return <option value={data.id}>{data.name}</option>
    } else {
      return <></>
    }
  }

  function LocationOptions({data}) {
    if (data && data.length) {
        return <form>
          <select
            className="mw-100"
            onChange={event=>setLocationId(event.target.value)}
            value={locationId}
          >
            <option key="unk" value="unk">Select the Location</option>
            {locations.map(el=><Location key={el.id} data={el} />)}
          </select>
        </form>
    } else {
      return <></>
    }
  }


  return (
    <div>
      <h1>Members</h1>
      <Switch>
        <Route exact path={path}>
        </Route>

        <Route path={`${path}/list`}>
          <LocationOptions data={locations} />
          <br/>
          <MemberList data={members} />
        </Route>

        <Route exact path={`${path}/:member_id`}>
          <Details />
        </Route>

        <Route exact path={`${path}/location/:location_id`}>
          <h3>location details</h3>
        </Route>
      </Switch>
    </div>
  )
}