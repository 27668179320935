class Classes {
  constructor(value) {
    this.value = value
    this.struct = value.split(' ')
  }

  valueOf() {
    return this.value
  }

  add(s) {
    this.struct.push(s)
  }

  remove(s) {
    this.struct.splice(this.struct.indexOf(s),1)
    this.value = this.struct.join(' ')
  }
}

export default Classes