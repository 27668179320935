import React,{useRef,useState} from 'react';
import {
  BrowserRouter as Router,
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import {
  Navbar,
  Nav,
} from 'react-bootstrap'
import Location from "./Location";
import Locations from "./Locations";
import Login from './user/Login_API'
import logo from './img/android-chrome-384x384.png';
import Members from './Members'
import useLocalStorage from "./lib/useLocalStorage";
import Coach from "./Coach";
import Test from "./Test";
import Logout from "./user/Logout";

import './css/bootstrap.min.css'
import './css/animate.min.css';
import './css/App.css';


export default function App() {
  const [user, setUser] = useLocalStorage('user',null)

  const [authenticated, setAuthenticated] = useState(false)
  const [navbarExpand, setNavbarExpand] = useState(false)

  const PrivateLink = ({children,className,show=true,to})=>{
    if (show) return <Link to={to} className={className}>{children}</Link>
    else return <></>
  }

  function PrivateNavLink({activeClassName='',children,className,show=true,to}) {
    if (show) {
      return <NavLink
        activeClassName={activeClassName} 
        as={Link}
        className={className+' nav-item nav-link'}
        onClick={unexpand}
        to={to} 
      >
        {children}
      </NavLink>
    }
    else return <></>
  }

  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          (authenticated && user) 
          ? ( children ) 
          : (
            <Login 
              setAuthenticated={setAuthenticated}
              setUser={setUser}
            />
          )
        }
      />
    );
  }

  const toggler = event=>{
    setNavbarExpand(navbarExpand ? false : "expanded")
  }
  
  const unexpand = event=>{
    setNavbarExpand(false)
  }

  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/">
          <h2 className="animate__animated animate__headShake">OsteoStrong Chroma</h2>
        </PrivateRoute>

        <PrivateRoute path="/location/:id">
          <Location />
        </PrivateRoute>
{/* 
        <PrivateRoute path="/locations">
          <Locations user={user} />
        </PrivateRoute> 
*/}

        <PrivateRoute path="/member">
          <Members user={user} />
        </PrivateRoute>
{/* 
        <PrivateRoute path="/coaching">
          <Coach />
        </PrivateRoute> 
*/}

        <PrivateRoute path="/coaching">
          <Coach />
        </PrivateRoute>

        <PrivateRoute path="/test">
          <Test />
        </PrivateRoute>

        <PrivateRoute path="/logout">
          <Logout 
            setAuthenticated={setAuthenticated}
            setUser={setUser}
          />
        </PrivateRoute>
      </Switch>

      <Navbar
        bg="dark"
        collapseOnSelect
        expand="sm"
        expanded={navbarExpand}
        onToggle={toggler}
        // onSelect={toggler}
        fixed="top"
        variant="dark"
      >
        <Navbar.Brand>
          <img
            alt=""
            height="30"
            width="30"
            src={logo}
            className="d-inline-block align-top"
          />{' '}
          OsteoStrong
        </Navbar.Brand>
        <Navbar.Toggle 
          aria-controls="responsive-navbar-nav" 
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <PrivateNavLink
              eventKey="0" 
              to="/"
            >Home</PrivateNavLink>
{/* 
            <PrivateNavLink
              activeClassName="highlight"
              eventKey="3" 
              show={authenticated} 
              to="/coaching"
            >Coaching</PrivateNavLink> 
*/}
            <PrivateNavLink
              activeClassName="highlight"
              eventKey="3" 
              show={authenticated} 
              to="/member/list"
            >Members</PrivateNavLink>
{/* 
            <PrivateNavLink
              activeClassName="highlight"
              eventKey="1" 
              show={authenticated} 
              to="/locations"
            >Locations</PrivateNavLink> 
*/}
            <PrivateNavLink
              activeClassName="highlight"
              eventKey="5" 
              show={authenticated}
              to="/logout"
            >Logout</PrivateNavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Router>
  );
}