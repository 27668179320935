import React,{useRef,useState} from 'react'
import useLocalStorage from "../lib/useLocalStorage";
import MachineProxy from "./MachineProxy";
import AddMachine from "./AddMachine";

function MachineList({member}) {
  const [addresses, setAddresses] = useState([])
  const [machines, setMachines] = useLocalStorage('machines', [])

  const clearHandler = event=>setMachines([])

  const scanHandler = event=>{
    const ips = []

    async function testConx(address) {
      try {
        const result = await fetch(address)
      } catch (error) {
        console.error(error)
      }
    }

    for (let I=0; I<255; I++) {
      const addr = `https://192.168.1.${I}:3000/machine`

      fetch(addr)
      .then(res=>console.log(`result ${addr}`, res))
      .catch(err=>console.log(`error ${addr}`, err))

      if (I>3) break
    }
  }

  function GenericMachine({el}) {
    function removeMachine() {
      const newList = machines.filter(mach=>mach.address!==el.address)
      setMachines(newList)
    }

    if (el && el.address) {
      return <li>
        <MachineProxy member={member} name={el.name} type={el.type} address={el.address} remove={removeMachine} />
      </li>
    } else {
      return <></>
    }
  }

  return (
    <div>
      <h2>Modalities</h2>

      <ul>
        {machines.map((el,i)=><GenericMachine el={el} key={i} />)}
      </ul>

      <AddMachine machines={machines} setMachines={setMachines} />
      <br/>
      <br/>
      <button onClick={clearHandler} className="delete button">Delete All</button>
    </div>
  )
}

export default MachineList